@keyframes slideInFromLeft {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.slider {  
  /* This section calls the slideInFromLeft animation we defined above */
  animation: 1s ease-out 0s 1 slideInFromLeft;
  
  background: #333;
  padding: 30px;
}

.rounded-top-card {
    border-top-left-radius: 175px; /* Adjust the radius as needed */
    border-top-right-radius: 175px; /* Adjust the radius as needed */
  }