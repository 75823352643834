/* Style the logo image */
.navbar-logo {
    height: 50px;
    border: 2px solid #000; /* Black border */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Slight shadow */
    /* Add other styles like padding, width, and height if needed */
  }

  .test {
    float: left;
}

.icon {
  width: 20px;
  height: 20px;
  fill: #ff8000;

}