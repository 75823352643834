@keyframes slideInFromLeftbg {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  .sliderbg {  
    /* This section calls the slideInFromLeft animation we defined above */
    animation: 1s ease-out 0s 1 slideInFromLeftbg;
  }