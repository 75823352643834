.timeline-with-icons {
  border-left: 1px solid #ff8000;
  position: relative;
  list-style: none;
}

.timeline-with-icons .timeline-item {
  position: relative;
}

.timeline-with-icons .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline-with-icons .timeline-icon {
  position: absolute;
  left: -48px;
  background-color: white;
  border-style: solid;
  border-color: #ff8000;
  color: hsl(217, 88.8%, 35.1%);
  border-radius: 50%;
  height: 31px;
  width: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-icon-svgs {
  width: 18px;
  fill: black
}

.experience-logo {
  align-items: center;
  width: 18px;
  height: 18px;
  overflow: visible;
}